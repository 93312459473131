import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import aws4 from 'aws4';
import users from '../server_backend/users.json';
import './index.css';

function SendDeliveryCode() {
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [deliveryCode, setDeliveryCode] = useState('');
  const [arrivalTime, setArrivalTime] = useState('');
  const [userType, setUserType] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const user = users.find((u) => u.email === userEmail);
    
    if (user) {
      setUserType(user.type);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!orderId || !deliveryCode || !arrivalTime) {
      alert('Todos os campos são obrigatórios.');
      return;
    }

    let queryParams = new URLSearchParams({
      orderId,
      deliveryCode,
      arrivalTime,
    }).toString();

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/SendDeliveryLink?${queryParams}`,
      method: 'POST',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({})
    };

    aws4.sign(opts, {
      accessKeyId: 'AKIA6ODUZGVV4F6BAABT',
      secretAccessKey: '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'
    });

    delete opts.headers['Host'];
    delete opts.headers['Content-Length'];

    try {
      setIsLoading(true);
      const response = await axios({
        url: `https://${opts.host}${opts.path}`,
        method: opts.method,
        headers: opts.headers,
        data: opts.body
      });

      if (response.status === 200) {
        alert('Código de rastreio enviado com sucesso.');
      } else {
        alert(`Falha ao enviar código de rastreio: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao enviar código de rastreio. Verifique o console para mais detalhes.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
        <li><a href="/auth-inicial">Home</a></li>
          {userType === 'admin' && <li><a href="/update-order">Atualizar pagamento</a></li>}
          {userType === 'admin' && <li><a href="/send-payment-link">Enviar link de pagamento</a></li>}
          <li><a href="/send-delivery-code">Enviar código de rastreio</a></li>
          <li><a href="/search-order">Informações de pedidos</a></li>
          <li><a href="/" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="UpdateOrder-box">
          <h1>Enviar Código de Rastreio</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Order ID:</label>
              <input
                type="text"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Código de Rastreio:</label>
              <input
                type="text"
                value={deliveryCode}
                onChange={(e) => setDeliveryCode(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Previsão de Chegada:</label>
              <input
                type="text"
                value={arrivalTime}
                onChange={(e) => setArrivalTime(e.target.value)}
                required
              />
            </div>
            <button 
              type="submit" 
              className="update-button"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  Enviando...
                </div>
              ) : (
                'Enviar código'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SendDeliveryCode;
