import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import users from '../server_backend/users.json';
import aws4 from 'aws4';
import './index.css';
import loadingIcon from '../loading.gif'; // ícone de carregamento

function RestaurantDashboard() {
  const [restaurantData, setRestaurantData] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [restaurantId, setRestaurantId] = useState('');
  const [userName, setUserName] = useState('');
  const [userProfilePicture, setUserProfilePicture] = useState('');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userEmail');
    navigate('/login');
  };

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const user = users.find((u) => u.email === userEmail);
    
    if (user) {
      setUserType(user.type);
      setUserName(user.name);
      setUserProfilePicture(user.profilePicture);
      
      console.log("User Type:", user.type);
      console.log("User Restaurant ID:", user.restaurantId); // Log para verificar o restaurantId

      if (user.type === 'restaurante') {
        setRestaurantId(user.restaurantId); // Define o restaurantId automaticamente
        fetchOrders(user.restaurantId); // Carrega as informações do dashboard automaticamente
      } else {
        setLoading(false); // Para o admin, vamos parar o carregamento
      }
    } else {
      navigate('/login'); // Redirecionar se não encontrar o usuário
    }
  }, [navigate]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const signRequest = (opts) => {
    aws4.sign(opts, {
      accessKeyId: 'AKIA6ODUZGVV4F6BAABT',
      secretAccessKey: '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'
    });

    delete opts.headers['Host'];
    delete opts.headers['Content-Length'];

    return opts;
  };

  const fetchOrders = async (restaurantId) => {
    setLoading(true); // Define loading como true antes de iniciar a chamada da API

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/GetAllOrderByRestaurantId?RestaurantId=${restaurantId}`,
      method: 'GET',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const signedOpts = signRequest(opts);
    
    console.log("Fetching Orders for Restaurant ID:", restaurantId); // Log para verificar o ID do restaurante

    try {
      const response = await fetch(`https://${signedOpts.host}${signedOpts.path}`, {
        method: signedOpts.method,
        headers: signedOpts.headers,
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`); // Adiciona erro se a resposta não for ok
      }

      const data = await response.json();
      console.log("Orders Data:", data); // Log para verificar os dados recebidos
      setOrdersData(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false); // Define loading como false após a chamada ser concluída
    }
  };

  const handleSearchByRestaurantId = (e) => {
    e.preventDefault();
    if (restaurantId) {
      fetchOrders(restaurantId);
    }
  };

  const countOrdersByStatus = (status) => {
    return ordersData.filter((order) => order.orderStatus === status).length;
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
        <li><a href="/auth-inicial">Home</a></li>
          {userType === 'admin' && <li><a href="/update-order">Atualizar pagamento</a></li>}
          {userType === 'admin' && <li><a href="/send-payment-link">Enviar link de pagamento</a></li>}
          <li><a href="/send-delivery-code">Enviar código de rastreio</a></li>
          <li><a href="/search-order">Informações de pedidos</a></li>
          <li><a href="/" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <br></br>
        <br></br>
        <br></br>
        <div className="dashboard">
          <header className="dashboard-header">
            <div className="welcome-box">
              <img src={userProfilePicture} alt="Foto do Perfil do Usuário" className="profile-picture" />
              <h1>Seja bem-vindo, {userName}!</h1>
            </div>
          </header>
          {userType === 'admin' && (
            <>
              <form onSubmit={handleSearchByRestaurantId}>
                <div className="form-group">
                  <label>Selecione o Restaurante:</label>
                  <select
                    value={restaurantId}
                    onChange={(e) => setRestaurantId(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    <option value="4349fb4e-7cae-4f03-889c-759f74359a16">Saudável Refeições Fit</option>
                    <option value="64d6e447-d6ae-4fe7-885e-a6c225f15773">Zolé Cozinha</option>
                  </select>
                </div>
                <button type="submit" className="search-button">Buscar Informações do Restaurante</button>
              </form>
            </>
          )}
          <br></br>
          <br></br>
          <div className="dashboard-body">
            <div className="orders-summary">
              <div className="order-box">
                {loading ? (
                  <img src={loadingIcon} alt="Loading..." className="loading-icon" />
                ) : (
                  <h2>{countOrdersByStatus(1)}</h2>
                )}
                <p>Pedidos finalizados</p>
              </div>

              <div className="order-box">
                {loading ? (
                  <img src={loadingIcon} alt="Loading..." className="loading-icon" />
                ) : (
                  <h2>{ordersData.filter((order) => order.paymentStatus === 1 && order.orderStatus === 2).length}</h2>
                )}
                <p>Pedidos em aberto</p>
              </div>

              <div className="order-box">
                {loading ? (
                  <img src={loadingIcon} alt="Loading..." className="loading-icon" />
                ) : (
                  <h2>{ordersData.filter((order) => order.paymentStatus === 0).length}</h2>
                )}
                <p>Pedidos aguardando pagamento</p>
              </div>

              <div className="order-box">
                {loading ? (
                  <img src={loadingIcon} alt="Loading..." className="loading-icon" />
                ) : (
                  <h2>{countOrdersByStatus(0)}</h2>
                )}
                <p>Pedidos cancelados</p>
              </div>
            </div>

            <div className="actions">
              <button onClick={() => navigate('/update-order')} className="action-button">
                Atualizar Status
              </button>
              <button onClick={() => navigate('/search-order')} className="action-button">
                Buscar Informações
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantDashboard;
