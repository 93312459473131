import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import users from '../server_backend/users.json';
import aws4 from 'aws4';
import axios from 'axios';
import './index.css';

function SearchOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [restaurantId, setRestaurantId] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [userType, setUserType] = useState('');
  const [loggedInUser, setLoggedInUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const user = users.find(u => u.email === userEmail);
    if (user) {
      setUserType(user.type);
      setLoggedInUser(user);
      if (user.type !== 'admin') {
        setRestaurantId(user.restaurantId);
      }
    }
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userEmail');
    navigate('/login');
  };

  const signRequest = (opts) => {
    aws4.sign(opts, {
      accessKeyId: 'AKIA6ODUZGVV4F6BAABT',
      secretAccessKey: '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'
    });

    delete opts.headers['Host'];
    delete opts.headers['Content-Length'];

    return opts;
  };

  const handleSearchByOrderId = async (orderId) => {
    if (!orderId) {
      alert('Order ID é obrigatório.');
      return;
    }

    setIsLoading(true);

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/GetOrderById?Id=${orderId}`,
      method: 'GET',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const signedOpts = signRequest(opts);

    try {
      const response = await fetch(`https://${signedOpts.host}${signedOpts.path}`, {
        method: signedOpts.method,
        headers: signedOpts.headers,
      });
      const data = await response.json();
      setOrderData(data);
      setOrdersData([]);
    } catch (error) {
      console.error('Error fetching order data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchByOrderIdEvent = async (event) => {
    event.preventDefault();
    if (!orderId) {
      alert('Order ID é obrigatório.');
      return;
    }

    setIsLoading(true);

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/GetOrderById?Id=${orderId}`,
      method: 'GET',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const signedOpts = signRequest(opts);

    try {
      const response = await fetch(`https://${signedOpts.host}${signedOpts.path}`, {
        method: signedOpts.method,
        headers: signedOpts.headers,
      });
      const data = await response.json();
      setOrderData(data);
      setOrdersData([]);
    } catch (error) {
      console.error('Error fetching order data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchByRestaurantId = async (event) => {
    event.preventDefault();
    if (!restaurantId) {
      alert('Restaurant ID é obrigatório.');
      return;
    }

    setIsLoading(true);

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/GetAllOrderByRestaurantId?RestaurantId=${restaurantId}`,
      method: 'GET',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const signedOpts = signRequest(opts);

    try {
      const response = await fetch(`https://${signedOpts.host}${signedOpts.path}`, {
        method: signedOpts.method,
        headers: signedOpts.headers,
      });
      const data = await response.json();
      setOrdersData(data);
      setOrderData(null);
    } catch (error) {
      console.error('Error fetching restaurant orders:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateOrderStatus = async (orderId) => {
    if (!orderId) {
      alert("Order ID é obrigatório.");
      return;
    }
  
    setIsLoading(true);
    let queryParams = '';

    queryParams = new URLSearchParams({
      id: orderId,
      orderStatus: 3,
    }).toString();

    const opts = {
      host: 'emh7viuil4.execute-api.us-east-2.amazonaws.com',
      path: `/hml/v1/Orders/UpdateStatus?${queryParams}`,
      method: 'POST',
      service: 'execute-api',
      region: 'us-east-2',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}) // Corpo vazio para a solicitação POST
    };

    aws4.sign(opts, {
      accessKeyId: 'AKIA6ODUZGVV4F6BAABT',
      secretAccessKey: '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'
    });

    // Remover cabeçalhos não permitidos pelo navegador
    delete opts.headers['Host'];
    delete opts.headers['Content-Length'];

    try {
      const response = await axios({
        url: `https://${opts.host}${opts.path}`,
        method: opts.method,
        headers: opts.headers,
        data: opts.body // Passar o corpo da solicitação
      });

      if (response.status === 200) {
        alert('Status do pedido atualizado com sucesso.');
      } else {
        alert(`Falha ao atualizar status do pedido: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      alert('Erro ao atualizar status do pedido. Verifique o console para mais detalhes.');
    } finally {
      setIsLoading(false); // Desativa o estado de loading independente de sucesso/erro
    }

    window.location.reload();

  };

  const calculateMaxDeliveryDate = (orderDate) => {
    const date = new Date(orderDate);
    date.setDate(date.getDate() + 5);
    return date.toLocaleDateString();
  };

  const getRemainingDays = (orderDate) => {
    const today = new Date();
    const deliveryDate = new Date(orderDate);
    deliveryDate.setDate(deliveryDate.getDate() + 5);
    const timeDiff = deliveryDate - today;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  };

  const getOrderBackgroundColor = (order) => {
    if (order.orderStatus === 2) {
      const remainingDays = getRemainingDays(order.orderDate);
      if (remainingDays < 1) {
        return '#ffcccc'; // Vermelho claro
      } else if (remainingDays < 3) {
        return '#ffffcc'; // Amarelo claro
      }
    }
    return '#ffffff'; // Branco
  };

  const sortOrders = (orders) => {
    return orders.sort((a, b) => {
      if (a.orderStatus === 2&& b.orderStatus === 1) {
        return getRemainingDays(a.orderDate) - getRemainingDays(b.orderDate);
      } else if (a.orderStatus === 1) {
        return -1;
      } else if (b.orderStatus === 1) {
        return 1;
      } else {
        return new Date(b.orderDate) - new Date(a.orderDate);
      }
    });
  };

  const renderOrderDetails = (order) => (
    <div className="orders-list">
      <h2>Detalhes do Pedido</h2>
    <div className="order-details" style={{ backgroundColor: getOrderBackgroundColor(order) }}>
      <p><strong>Order ID:</strong> {order.id}</p>
      <p><strong>Número do Pedido:</strong> {order.orderNumber}</p>
      <p><strong>Status do Pedido:</strong> {translateOrderStatus(order.orderStatus)}</p>    
      <p><strong>Nome do Cliente:</strong> {order.clientName}</p>
      <p><strong>Data do Pedido:</strong> {new Date(order.orderDate).toLocaleString()}</p>
      {order.orderStatus === 2&& (
        <p><strong>Data Máxima de Entrega:</strong> {calculateMaxDeliveryDate(order.orderDate)}</p>
      )}
      <p><strong>Status do Pagamento:</strong> {translatePaymentStatus(order.paymentStatus)}</p>
      <p><strong>Subtotal:</strong> R$ {order.subtotalPrice?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <p><strong>Taxa de Entrega:</strong> R$ {order.deliveryFee?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <p><strong>Desconto:</strong> R$ {order.discountValue?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <p><strong>Total:</strong> R$ {order.totalPrice?.toFixed(2).replace('.', ',') ?? 'N/A'}</p>
      <h3>Itens do Pedido</h3>
      {order.meals && order.meals.length > 0 ? (
        order.meals.map((meal, index) => (
          <div key={index} className="meal">
            <p><strong>Nome:</strong> {meal.name}</p>
            <p><strong>Preço:</strong> R$ {meal.price.toFixed(2).replace('.', ',')}</p>
            <p><strong>Quantidade:</strong> {meal.quantity}</p>
            <p><strong>Ingredientes:</strong></p>
            <ul>
              {meal.ingredients.map((ingredient, idx) => (
                <li key={idx}>
                  {ingredient.food}: {parseFloat(ingredient.amount).toFixed(0)} {translateUnit(ingredient.unit)}
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p>Não há itens no pedido.</p>
      )}
      <h3>Endereço de Entrega</h3>
      {order.deliveryAddress ? (
        <div className="address">
          <p><strong>Endereço:</strong> {order.deliveryAddress.address}</p>
          <p><strong>Número:</strong> {order.deliveryAddress.number}</p>
          <p><strong>Cidade:</strong> {order.deliveryAddress.city}</p>
          <p><strong>Estado:</strong> {order.deliveryAddress.state}</p>
          <p><strong>CEP:</strong> {order.deliveryAddress.zipCode}</p>
          <p><strong>Complemento:</strong> {order.deliveryAddress.complement}</p>
        </div>
      ) : (
        <p>Não há endereço de entrega registrado.</p>
      )}
    </div>
    {order.orderStatus === 2 && (      
            <button
            className="update-button"
            onClick={() => handleUpdateOrderStatus(order.id)}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading-container">
                <div className="spinner"></div>
                Enviando...
              </div>
            ) : (
              'Concluir pedido e seguir para etapa de entrega'
            )}
          </button>
      )}
    </div>
  );

  const renderOrdersList = (orders) => (
    <div className="orders-list">
      <h2>Pedidos do Restaurante</h2>
      {sortOrders(orders).map((order, index) => (
        <div key={index} className="order-summary" style={{ backgroundColor: getOrderBackgroundColor(order) }}>
          <br></br>
          <p><strong>Order ID:</strong> {order.id}</p>
          <p><strong>Nome do Cliente:</strong> {order.clientName}</p>
          <p><strong>Número do Pedido:</strong> {order.orderNumber}</p>
          <p><strong>Status do Pagamento:</strong> {translatePaymentStatus(order.paymentStatus)}</p>
          <p><strong>Status do Pedido:</strong> {translateOrderStatus(order.orderStatus)}</p>
          {order.orderStatus === 2 && (
            <p><strong>Data Máxima de Entrega:</strong> {calculateMaxDeliveryDate(order.orderDate)}</p>
          )}
          <button className="update-button" onClick={() => handleSearchByOrderId(order.id)}>
            Abrir pedido
          </button>    
        </div>
      ))}
    </div>
  );

  const translatePaymentStatus = (status) => {
    switch (status) {
      case 0: return 'Pendente';
      case 1: return 'Pago';
      case 2: return 'Cancelado';
      case 3: return 'Reembolsado';
      default: return 'Desconhecido';
    }
  };

  const translateOrderStatus = (status) => {
    switch (status) {
      case 0: return 'Cancelado';
      case 1: return 'Concluído';
      case 2: return 'Em Andamento';
      case 3: return 'Pedido pronto pra entrega'
      default: return 'Desconhecido';
    }
  };

  const translateUnit = (unit) => {
    switch (unit) {
      case 0: return 'gramas';
      case 1: return 'ml';
      default: return '';
    }
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
        <li><a href="/auth-inicial">Home</a></li>
          {userType === 'admin' && <li><a href="/update-order">Atualizar pagamento</a></li>}
          {userType === 'admin' && <li><a href="/send-payment-link">Enviar link de pagamento</a></li>}
          <li><a href="/send-delivery-code">Enviar código de rastreio</a></li>
          <li><a href="/search-order">Informações de pedidos</a></li>
          <li><a href="/" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="SearchOrder-box">
          <h1>Buscar Pedido</h1>
          {userType === 'admin' && (
            <form onSubmit={handleSearchByRestaurantId}>
              <div className="form-group">
                <label>Selecione o Restaurante:</label>
                <select
                  value={restaurantId}
                  onChange={(e) => setRestaurantId(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  <option value="4349fb4e-7cae-4f03-889c-759f74359a16">Saudável Refeições Fit</option>
                  <option value="64d6e447-d6ae-4fe7-885e-a6c225f15773">Zolé Cozinha</option>
                </select>
              </div>
              <button
                type="submit"
                className="update-button"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loading-container">
                    <div className="spinner"></div>
                    Procurando...
                  </div>
                ) : (
                  'Buscar todos os pedidos'
                )}
              </button>
            </form>
          )}
          {userType !== 'admin' && (
            <form onSubmit={handleSearchByRestaurantId}>
              <button type="submit" className="search-button">Buscar todos os pedidos</button>
            </form>
          )}
          <form onSubmit={handleSearchByOrderIdEvent}>
            <div className="form-group">
              <br></br>
              <label>Order ID:</label>
              <input
                type="text"
                value={orderId}
                placeholder='Insira a Order ID'
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="update-button"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  Procurando...
                </div>
              ) : (
                'Buscar por Order Id'
              )}
            </button>
          </form>
          {orderData && renderOrderDetails(orderData)}
          {ordersData.length > 0 && renderOrdersList(ordersData)}
        </div>
      </div>
    </div>
  );
}

export default SearchOrder;